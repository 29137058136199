$(() => {
  $('.recent-posts-categories').each(function () {
    let $categories = $(this).find('.categories')
    let $picker = $categories.find('a.active').clone()
    $picker
      .removeClass('active')
      .addClass('picker')
      .on('click', function (event) {
        event.preventDefault()
        $(this).toggleClass('expanded')
        $categories.slideToggle()
      })
    $categories.before($picker)
  })
})
